import React from "react"
import { Link } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import * as style from "./style.module.scss"
import { Tooltip } from "antd"

const Menu = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.settings.theme)
  const sidebarSwitched = useSelector((state) => state.settings.sidebarSwitched)

  const switchMegaMenu = () => {
    dispatch({
      type: "settings/SWITCH_MEGA_MENU",
    })
  }

  const switchSidebar = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'sidebarSwitched',
        value: !sidebarSwitched,
      },
    })
  }

  const changeTheme = () => {
    dispatch({
      type: "settings/CHANGE_THEME",
      theme: theme === "default" ? "dark" : "default",
    })
  }

  return (
    <div className="ray__block mb-0">
      <div className={style.menu}>
        <div>
          <span
            className={`${style.switchSidebar} d-inline d-sm-none font-size-18`}
            onClick={switchSidebar}
            onKeyPress={switchSidebar}
          >
            {!sidebarSwitched && <i className="ri ri-more_vertical" />}
            {sidebarSwitched && <i className="ri ri-close" />}
          </span>
        </div>
        <div>
          <Link to="/" className={style.logo}>
            RayGraph
          </Link>
        </div>
        <div className={style.menuItems}>
          {/* <Link to="/" activeClassName={style.linkActive}>
            Home
          </Link> */}
          <a className={style.linkDisabled}>
            <Tooltip title="Soon" placement="right">Graphs</Tooltip>
          </a>
          <a className={style.linkDisabled}>
            <Tooltip title="Soon" placement="right">Studio</Tooltip>
          </a>
          <a className={style.linkDisabled}>
            <Tooltip title="Soon" placement="right">Docs</Tooltip>
          </a>
        </div>
        <div className={style.menuButtons}>
          <Tooltip title="Soon" placement="left">
            <span
              className="ant-btn ray__btn ray__btn--round me-2 me-md-3"
            >
              <i className="ri ri-add me-0 me-sm-2" />
              <span className="d-none d-sm-inline">Connect</span>
            </span>
          </Tooltip>
          <span
            className="ant-btn ray__btn ray__btn--transparent ray__btn--icon ray__btn--round me-2 me-md-3 px-3"
            onClick={changeTheme}
            onKeyPress={changeTheme}
          >
            <i
              className={`ri ri-moon ${theme === "default" ? "" : "d-none"}`}
            />
            <i
              className={`ri ri-sun ${theme !== "default" ? "" : "d-none"}`}
            />
          </span>
          <span
            className="ant-btn ray__btn ray__btn--transparent ray__btn--icon ray__btn--round px-3"
            onClick={switchMegaMenu}
            onKeyPress={switchMegaMenu}
          >
            <i className="ri ri-menu" />
          </span>
        </div>
      </div>
    </div>
  )
}

export default Menu
